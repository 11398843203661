import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Noir-Tattoo-Studio
			</title>
			<meta name={"description"} content={"Entfesseln Sie Ihren inneren Künstler im Noir-Tattoo-Studio"} />
			<meta property={"og:title"} content={"Noir-Tattoo-Studio"} />
			<meta property={"og:description"} content={"Entfesseln Sie Ihren inneren Künstler im Noir-Tattoo-Studio"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/pexels-photo-2183132.jpeg?v=2024-07-11T13:29:40.477Z) center/cover"
			padding="64px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
						Willkommen im Tattoostudio Noir-Tattoo-Studio
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					color="--light"
					font="--lead"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Wir im Noir-Tattoo-Studio glauben, dass Ihr Körper eine Leinwand ist und jedes Tattoo eine einzigartige Geschichte erzählt. Unsere Mission ist es, Ihre Vision durch die Kombination von Kreativität, Präzision und Leidenschaft in ein Meisterwerk zu verwandeln. Egal, ob Sie sich zum ersten Mal tätowieren lassen oder ein erfahrener Tattoo-Enthusiast sind, unsere talentierten Künstler sind hier, um Ihnen ein außergewöhnliches Erlebnis zu bieten, das Sie für immer in Erinnerung behalten werden.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13:29:40.438Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb61b1d712a00233610e8/images/tattoo-arm.png?v=2024-07-11T13%3A29%3A40.438Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Über unser Studio
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="70%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					Das Noir-Tattoo-Studio widmet sich der Kunst des Tätowierens. Unser talentiertes Team aus professionellen Künstlern ist auf eine breite Palette von Stilen spezialisiert und stellt sicher, dass wir jede Idee mit atemberaubenden Details und handwerklichem Können zum Leben erwecken können. Von komplizierten schwarzen und grauen Mustern bis hin zu lebendigen Vollfarbkreationen haben wir die Erfahrung, das perfekte Tattoo für Sie zu kreieren. Unser Studio ist ein Hort der Kreativität, in dem Hygiene, Sicherheit und Kundenzufriedenheit für uns oberste Priorität haben.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 0 0">
			<Text margin="0px 0px 0 0px" color="#ffffff" font="--headline2" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Warum sollten Sie sich für Noir-Tattoo-Studio entscheiden?
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Außergewöhnliche Künstler
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Unser Team besteht aus erfahrenen und hochqualifizierten Tätowierern, die ihre Arbeit mit Leidenschaft ausüben. Jeder Künstler bringt seinen eigenen einzigartigen Stil und seine eigene Perspektive ein und bietet unseren Kunden eine große Vielfalt an Möglichkeiten.
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Sterile Umgebung
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					Wir halten uns an höchste Hygiene- und Sicherheitsstandards und verwenden modernste Geräte und Sterilisationsmethoden. Ihre Gesundheit und Ihr Wohlbefinden haben für uns oberste Priorität.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Sonderanfertigungen
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Wir glauben, dass jedes Tattoo so einzigartig sein sollte wie die Person, die es trägt. Unsere Künstler arbeiten eng mit Ihnen zusammen, um individuelle Designs zu erstellen, die Ihre Persönlichkeit und Geschichte widerspiegeln.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Gemütliche Atmosphäre
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					Unser Studio ist so gestaltet, dass Sie sich wohlfühlen. Wir garantieren, dass Ihr Tattoo in einer entspannten und angenehmen Umgebung so angenehm wie möglich wird.
				</Text>
			</Box>
		</Section>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				md-width="100%"
				font="--headline3"
				order="1"
			>
				<Text
					font="--headline3"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
				>
					Unsere Erfahrung und Vision
				</Text>
			</Box>
			<Box
				empty-border-color="LightGray"
				width="70%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="100px 0px 0px 0px"
					color="--light"
					font="--headline5"
				>
					Bei Noir-Tattoo-Studio sind wir stolz darauf, qualitativ hochwertige Tattoos anzubieten, die Ihre Erwartungen übertreffen. Unsere Künstler verbessern ständig ihre Fähigkeiten und bleiben über die neuesten Techniken und Trends in der Tattoo-Branche auf dem Laufenden. Dieses Engagement für Exzellenz ermöglicht es uns, Ihnen modernstes Design und ein wirklich personalisiertes Erlebnis zu bieten. Egal, ob Sie eine klare Vorstellung haben oder Hilfe bei der Erstellung eines individuellen Designs benötigen, unsere Künstler stehen Ihnen bei jedem Schritt zur Seite.
				</Text>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/493475f9-8b3a-5cf6-b310-d99a0a3b3347.webp?v=2024-07-11T13:29:40.465Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Bereit für Veränderungen?
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Machen Sie den ersten Schritt zu Ihrem neuen Tattoo, indem Sie das Noir-Tattoo-Studio besuchen. Unsere talentierten Künstler freuen sich darauf, mit Ihnen zusammenzuarbeiten und ein Kunstwerk zu schaffen, das Sie ein Leben lang mit Stolz tragen werden. Vereinbaren Sie noch heute einen Termin und lassen Sie uns Ihnen helfen, Ihren inneren Künstler zu entdecken.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Unsere Kontakte
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});